// Import gettext.js
// import messagesEn from '@lang/en/LC_MESSAGES/default.po';
import messagesEn from "@lang/en/LC_MESSAGES/default.json";
import gettext from "gettext.js";
// import gettext from "gettext.js";
const i18n = gettext();
if(i18n.getLocale() == 'en')
{
	i18n.loadJSON(messagesEn);
}
// Create aliases for gettext functions
export const _t = (msgid: string, ...args: any[]) => i18n.__(msgid, ...args);
export const _n = (msgid: string, msgid_plural: string, n: number, ...args: any[]) => i18n._n(msgid, msgid_plural, n, ...args);
export const _p = (msgctxt: string, msgid: string, ...args: any[]) => i18n._p(msgctxt, msgid, ...args);

